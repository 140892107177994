import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useDispatch, useSelector} from 'react-redux';
import { SET_DRAWER } from '../redux/actions/types';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import mineLogo from '../assets/mining.svg';
import mineLogoSelected from '../assets/mining_selected.svg'
import Avatar from '@mui/material/Avatar';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded';
import { Link, useLocation  } from 'react-router-dom';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import airdrop from '../assets/airdrop.svg';
import airdropSelected from '../assets/airdropselected.svg';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


export default function Footer() {

  const application = useSelector(state => ({
    ...state.application
  }));
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const location = useLocation()



  const toggleDrawer = () => {
    dispatch({
      type: SET_DRAWER,
      payload: { isDrawerOpen: !application.isDrawerOpen}
    });
  }

  const homePageUrl = '/home';
  const dashBoardUrl = ['dashboard', 'deposit-statement', 'rewards-statement', 'withdrawal-statement'];
  const userUrl = ['users'];
  
  React.useEffect(() => {
    if (homePageUrl.includes(location.pathname)){
      setValue(0);
    }
    else if (location.pathname.replace('/', '').match('team') || location.pathname.replace('/', '').match('team')){
      setValue(1);
    }
    else if ( location.pathname.replace('/', '').match('me') || location.pathname.replace('/', '').match('me')){
      setValue(2);
    }

  }, []);
      

  return (
    <Paper className="footer" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000000 }} elevation={3}>
    <BottomNavigation
      showLabels
      style={{backgroundColor: 'transparent'}} 
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
   
      <BottomNavigationAction component={Link} to="/home" style={{opacity: value === 0 ? '1' : '0.9', color: value === 0  ? '#1976d2' :  '#fff'}} icon={
              <Avatar style={{background: 'transparent'}} sx={{ width: 40, height: 40 }}>
                      {value == 0 && <img style={{width: 30}} src={airdropSelected} alt="mine"/>}
                      {value != 0 && <img style={{width: 30}} src={airdrop} alt="mine"/>}                    
              </Avatar>} />
 
      <BottomNavigationAction component={Link} to="/team" style={{opacity: value === 1 ? '1' : '0.5', color: value === 1  ? '#1976d2' :  '#fff'}} icon={<WorkspacesIcon />} />
      <BottomNavigationAction component={Link} to="/me"  style={{opacity: value === 2 ? '1' : '0.5', color: value === 2  ? '#1976d2' :  '#fff' }} icon={<AccountCircleIcon/>} />
    </BottomNavigation>
  </Paper>
  );
}
