import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import secureAxios from '../secureAxios';

import Web3 from "web3";
import Web3Modal from "web3modal";
import { ethers } from 'ethers';

import web3ModalConfig from "../providerOption";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';

import mainlogo from '../assets/rocket2.png';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



function Copyright(props) {
  return (
    <Typography variant="body2"  sx={{color: '#fff'}} align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {

  const dispatch = useDispatch();
  let history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const application = useSelector(state => ({
    ...state.application
  }));

  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };


  React.useEffect(() => {
    if (application.isAuthenticated === true){
      history.push("/home")
    }
  }, []); 
  

  const login_submit_with_cred = (data) => {
    setLoading(true);
    secureAxios.post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/login`, data).then(response => {

      if (response.data?.success){
        localStorage.setItem('accessToken', response.data?.token?.access);
        dispatch({
          type: LOGIN,
          payload: {
            profile: {
              account: data['address'],
              chainId: 56,          
            },
            isAuthenticated: true
          }
        });
        history.push("/home");
      }
    

    }).catch(error=>{
      setLoading(false)
      if (error.response.data?.non_field_errors){
        alert(error.response.data?.non_field_errors)
      }
      else{
        alert(error.response.data?.detail)
      }
    })
  }

  const login_submit_with_wallet = async () => {
    const provider = await web3ModalConfig.connect();
    const library = new ethers.providers.Web3Provider(provider);
    const accounts = await library.listAccounts();
    const network = await library.getNetwork();

    if (network?.chainId !== 56){
      alert("Wrong network selected")
      return;
    }

    login_submit_with_cred({
      email: "",
      password: "",
      address: accounts[0]
    })

  };


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const _data = {
      email: data.get('email'),
      password: data.get('password'),
      address: ""
    }
    login_submit_with_cred(_data);
  };

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={mainlogo} style={{width: '160px', height: '40px'}}/>
          <Typography sx={{color: '#fff'}} component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              InputLabelProps={{
                style: { color: '#fff', fontSize: '12px' },
            }} 
            inputProps={{ style: { color: "#fff" } }} 
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              InputLabelProps={{
                style: { color: '#fff', fontSize: '12px' },
            }} 
            InputProps={{
              style: { color: '#fff' },
              endAdornment: (
                  <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          edge="end"
                      >
                          {showPassword ? <Visibility style={{color: '#fff'}} /> : <VisibilityOff style={{color: '#fff'}}/>}
                      </IconButton>
                  </InputAdornment>
              ),
          }}
            />
            <Button
              type={!loading ? "submit"  : 'button'}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {!loading ? 'Sign In' : 'Working ....'}
            </Button>

            <Divider size="small" style={{color: '#fff'}}>OR</Divider>

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={login_submit_with_wallet}
            >
              Login with Wallet
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/forget" style={{color: '#fff', textDecoration: 'none'}} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/signup" style={{color: '#fff', textDecoration: 'none'}} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      </div>
  );
}