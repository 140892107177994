import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useDispatch, useSelector} from 'react-redux';
import { SET_DRAWER } from '../redux/actions/types';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';
import { useHistory } from "react-router-dom";
import mainlogo from '../assets/rocket2.png';
import btt from '../assets/btt.svg'
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';

import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';


export default function Header() {

  const application = useSelector(state => ({
    ...state.application
  }));
  const [auth, setAuth] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  let history = useHistory()


 


  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const toggleDrawer = () => {
    dispatch({
      type: SET_DRAWER,
      payload: { isDrawerOpen: !application.isDrawerOpen}
    });
  }

  const Logout = () => {
    dispatch({
      type: LOGIN,
      payload: {
        profile: {},
        isAuthenticated: false,
        accessToken: {},
        groups: {},
        isDrawerOpen: false
      }
    });
    history.push("/login")
  }
 
    

  return (
    <Box sx={{ flexGrow: 1 }} style={{paddingBottom: '30px',  overflow: 'hidden'}}>
      <AppBar position="fixed" style={{background: 'rgb(16, 20, 24)', overflow: 'hidden'}}>
        <Toolbar>
            <Grid container direction="row">
            <Grid item>
              <Avatar sx={{ width: 140, marginLeft: '15px' }}  style={{background: 'transparent', borderRadius: 0}}>
                  <img style={{width: 140}} src={mainlogo} alt="fireSpot"/>
              </Avatar>
            </Grid>
          </Grid>

          {application.isAuthenticated && (
            <div>
              <Button onClick={Logout} variant='outlined'>Logout</Button>
            </div>
          )}
          {
            !application.isAuthenticated && (
              <Button onClick={()=>history.push("/login")} variant='outlined'>Login</Button>
            )
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}
