import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import secureAxios from '../secureAxios';
import { useHistory } from "react-router-dom";
import mainlogo from '../assets/rocket2.png';


function Copyright(props) {
  return (
    <Typography variant="body2"  sx={{color: '#fff'}} align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function ForgotPass() {

  const [isOtp, setIsOTP] = React.useState('')
  const [showChange, setShowChange] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let history = useHistory();

  const find_me = (data) => {
    setLoading(true);
    secureAxios.post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/forget_pass_otp`, data).then(response => {

      if (response.data?.success){
        setShowChange(true);
      }
      
      setLoading(false)

    }).catch(error=>{
      setLoading(false)
      if (error.response.data?.non_field_errors){
        alert(error.response.data?.non_field_errors)
      }
      else{
        alert(error.response.data?.detail)
      }
    })
  }

  const forget_pass = (data) => {
    setLoading(true);
    secureAxios.post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/forget_pass`, data).then(response => {

      if (response.data?.success){
        alert('Password successfully changed');
        history.push("/login");
      }
      
      setLoading(false)

    }).catch(error=>{
      setLoading(false)
      if (error.response.data?.non_field_errors){
        alert(error.response.data?.non_field_errors)
      }
      else{
        alert(error.response.data?.detail)
      }
    })
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    find_me({
      email: data.get('email'),
    })
    
    
  };

  const handleSubmitForgot = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    forget_pass(
      {
        email: data.get('email'),
        otp: data.get('otp'),
        password: data.get('password')
      }
    )
    
    
  };


  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={mainlogo} style={{width: '160px', height: '40px'}}/>
          <Typography sx={{color: '#fff'}} component="h1" variant="h5">
            Foget Password
          </Typography>
          <Box component="form" onSubmit={showChange ? handleSubmitForgot : handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              InputLabelProps={{
                style: { color: '#fff', fontSize: '12px' },
            }} 
            inputProps={{ style: { color: "#fff" } }} 
            />
            {showChange &&
              <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="otp"
                label="OTP"
                name="otp"
                InputLabelProps={{
                  style: { color: '#fff', fontSize: '12px' },
                }} 
                inputProps={{ style: { color: "#fff" } }} 
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                InputLabelProps={{
                  style: { color: '#fff', fontSize: '12px' },
                }} 
                inputProps={{ style: { color: "#fff" } }} 
              />
            </>
            
            }
            <Button
              type={!loading ? "submit" : 'button'}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? 'Working...' : (!showChange ? 'Find Me' : 'Change Password')}
            </Button>
           
            <Grid container>
              <Grid item xs>
                <Link href="login" variant="body2">
                  Login
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      </div>
  );
}