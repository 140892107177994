import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import gradinant from '../assets/gradinat.webp'
import Divider from '@mui/material/Divider';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';

import bnbLogo from '../assets/bnb.png';
import metaLogo from '../assets/meta.png'
import WalletIcon from '@mui/icons-material/Wallet';
import Banner from '../assets/banner.png'

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Button } from '@mui/material';

import TextField from '@mui/material/TextField';
import Countdown from 'react-countdown';

import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {isMobile} from 'react-device-detect';

import PersonIcon from '@mui/icons-material/Person';
import Chip from '@mui/material/Chip';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';

import { useHistory } from "react-router-dom";
import secureAxios from '../secureAxios';

import { CopyToClipboard } from 'react-copy-to-clipboard';


const logos = {
    'bnb': bnbLogo,
    'metapad': metaLogo
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const Recurit = () => {

    const [value, setValue] = React.useState(0);
    const [detail, setDetail] = React.useState(
      {'profile': {
      username: "METAPAD"
      }});
    const [loading, setLoading] = React.useState(false);
    let history = useHistory()
    const totalNumber = 5500000000;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const details = {
        teams: [
            {labelName: 'My Team Lavel 1', values: []},
            {labelName: 'My Team Lavel 2', values: []}
        ]

    }

    const user_details = (data) => {
      setLoading(true);
      secureAxios.get(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/info`, data).then(response => {
  
        if (response.data){
          setLoading(false);
          setDetail(response?.data)
        }
      
  
      }).catch(error=>{
        setLoading(false)
        if (error.response.data?.non_field_errors){
          alert(error.response.data?.non_field_errors)
        }
        else{
          alert(error.response.data?.detail)
        }
      })
  }

  React.useEffect(()=>{
    user_details()},
 [])

    const formatNumber = (num) => {
        if (num >= 1e9) {
          return (num / 1e9).toFixed(1) + ' B';
        }
        if (num >= 1e6) {
          return (num / 1e6).toFixed(1) + ' M';
        }
        if (num >= 1e3) {
          return (num / 1e3).toFixed(1) + ' K';
        }
        return num.toString();
    };

    function stringToDate(dateString) {
        const [datePart, timePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hour, minute, second] = timePart.split(':').map(Number);
        
        // JavaScript months are zero-based, so we subtract 1 from the month
        return new Date(year, month - 1, day, hour, minute, second);
      }
  
      const CountdownTimer = ({date}) => {
  
        // Renderer function to format the countdown
        const renderer = ({ days, hours, minutes, seconds }) => {
          // Pad single digit numbers with leading zeros
          const pad = (num) => String(num).padStart(2, '0');
      
          return (
            <Typography variant="subtitle1" fontWeight={"bold"}  >
              {pad(days)}d : {pad(hours)}h : {pad(minutes)}m 
            </Typography>
          );
        };
        return (
            <Countdown
              date={date}
              renderer={renderer}
            />
          );
    };

    
    return (
        <>
        
        <Box sx={{ flexGrow: 1, backgroundColor: `rgb(31, 31, 31)` }}>
            <AppBar sx={{ flexGrow: 1, backgroundColor: `rgb(31, 31, 31)` }} position="static">
                <Toolbar>
                    <KeyboardArrowLeftIcon onClick={()=>history.push("/home")}/>
                    <Typography textAlign={'center'} variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Recruit My Teammates
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>

        <Box sx={{ backgroundColor: `rgb(31, 31, 31)`, marginTop: '15px', padding: '20px' }}>
            <Typography textAlign={'left'} variant="subtitle" fontSize={'13px'} component={'div'} sx={{ color: '#fff', width: '100%', whiteSpace: 'pre-line' }}>
                You need to recruit teammates to run your team, the more teammates you have, the more you can
                increase your team's hash
                (Personal hash + Team hash + Project hash = Total hash of
                participating project airdrops)
            </Typography>
            <Typography textAlign={'left'} variant="subtitle" fontSize={'13px'} component={'div'} sx={{ color: '#fff', marginTop: '15px', width: '100%', whiteSpace: 'pre-line' }}>
                [Teammates Level-1] 
            </Typography>
            <Typography textAlign={'left'} variant="subtitle" fontSize={'13px'} component={'div'} sx={{ color: '#fff', marginTop: '15px', width: '100%', whiteSpace: 'pre-line' }}>
                Your directly recruited teammate, whose 50% effective hash > contributes to your team hash
            </Typography>

            <Typography textAlign={'left'} variant="subtitle" fontSize={'13px'} component={'div'} sx={{ color: '#fff', marginTop: '15px', width: '100%', whiteSpace: 'pre-line' }}>
                [Teammates Level-2]
            </Typography>
            <Typography textAlign={'left'} variant="subtitle" fontSize={'13px'} component={'div'} sx={{ color: '#fff', marginTop: '15px', width: '100%', whiteSpace: 'pre-line' }}>
            
                Teammates recruited by your teammate Level 2, whose 25% effective hash > contributes to your team hash
            </Typography>

            <Typography textAlign={'left'} variant="subtitle" fontSize={'13px'} component={'div'} sx={{ color: '#fff', marginTop: '15px', width: '100%', whiteSpace: 'pre-line' }}>
                [Teammates Level-3]
            </Typography>
            <Typography textAlign={'left'} variant="subtitle" fontSize={'13px'} component={'div'} sx={{ color: '#fff', marginTop: '15px', width: '100%', whiteSpace: 'pre-line' }}>
            
                Teammates recruited by your teammate Level 3, whose 12.5% effective hash > contributes to your team hash
            </Typography>

            <Typography textAlign={'left'} variant="subtitle" fontSize={'8px'} component={'div'} sx={{ color: '#fff', marginTop: '15px', width: '100%', whiteSpace: 'pre-line' }}>
            
                (Teammates effective hash refers to the hash that is being airdropped, not participating in or stopping airdrops means that the effective hash is
0)
                (Teammates contribute to their team's hash, their own hash is not affected)
            </Typography>

            <Typography textAlign={'left'} variant="subtitle" fontSize={'15px'} component={'div'} sx={{ color: '#fff', marginTop: '15px', width: '100%', whiteSpace: 'pre-line' }}>
                Recruiting teammates URL
            </Typography>
            
            <Typography textAlign={'left'} variant="subtitle" fontSize={'15px'} component={'div'} sx={{ color: '#fff', marginTop: '15px', width: '100%', whiteSpace: 'pre-line' }}>
                <a style={{color: '#fff', textDecoration: 'underline'}} href={`https://metapad.cc/signup?referral_id=${detail?.profile?.username}`} >https://metapad.cc/signup?referral_id={detail?.profile?.username}</a>
            </Typography>

            <CopyToClipboard text={`https://metapad.cc/signup?referral_id=${detail?.profile?.username}`} onCopy={() => alert("Copy successfull")}>

              <Button style={{marginTop: '10px'}} variant='outlined'>Copy URL</Button>

            </CopyToClipboard>

        </Box>
        
        </>
    )
}

export default Recurit