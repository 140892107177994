import logo from './logo.svg';
import './App.css';
import Routes from './routes.js';
import { useDispatch, useSelector} from 'react-redux';
import * as React from 'react';

import { LOGIN, LOGOUT, SET_ADMIN } from './redux/actions/types.js';



function App() {

  return (
      <div className="App">
        <Routes/>
      </div>
  );
}

export default App;
