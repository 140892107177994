import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import secureAxios from '../secureAxios';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN, LOGOUT, SET_ADMIN } from '../redux/actions/types';
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import mainlogo from '../assets/rocket2.png';
import { connectWallet } from '../utils/transferTokensFunction';
import Web3 from 'web3';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IBackdrop from '../components/backdrop.js';

function Copyright(props) {
  return (
    <Typography variant="body2"  sx={{color: '#fff'}} align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp() {

  const [loading, setLoading] = React.useState(false);
  const [address, setAddress] = React.useState('');

  const [showPassword, setShowPassword] = React.useState(false);

  

  const dispatch = useDispatch();
  let history = useHistory();

  const value=queryString.parse(window.location.search);
  const token=value.referral_id;

  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const web3 = new Web3();

    
    if (!web3.utils.isAddress(data.get('web3_add').trim())){

      alert("Invalid address");
      return false

    }
    
    check_sponer({
      sponser: data.get('sponser_id'),
      address: data.get('web3_add'),
      email: data.get('email').trim(),
      password: data.get('password'),
    })
  };

  const check_sponer = (data) => {
    setLoading(true);
    secureAxios.post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/check_sponser`, data).then(response => {

      if (response.data?.success){
        Register(data)
      }
    

    }).catch(error=>{
      setLoading(false)
      if (error.response.data?.non_field_errors){
        alert(error.response.data?.non_field_errors)
      }
      else{
        alert(error.response.data?.detail)
      }
    })
  }

  const Register = (data) => {
    setLoading(true);
    secureAxios.post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/register`, data).then(response => {
      if (response.data?.success){
        localStorage.setItem('accessToken', response.data?.token?.access);
        dispatch({
          type: LOGIN,
          payload: {
            profile: {
              account: response?.data['address'],
              chainId: 56,          
            },
            isAuthenticated: true
          }
        });
        history.push("/home");
      }
    

    }).catch(error=>{
      setLoading(false)
      if (error.response.data?.non_field_errors){
        alert(error.response.data?.non_field_errors)
      }
      else{
        alert(error.response.data?.detail)
      }
    })
  }

  React.useEffect(async () =>{
    const {networks, accounts} = await connectWallet();
    console.log(accounts)
    if (accounts.length > 0){
      setAddress(accounts[0])
    }
  }, [])

  return (
    <div>
      <IBackdrop isOpen={loading}/>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <img src={mainlogo} style={{width: '160px', height: '40px'}}/>

          <Typography sx={{color: '#fff'}} component="h1" variant="h5">
            Register
          </Typography>
          <Box component="form" onSubmit={!loading && handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="sponser_id"
              label="Sponser Id"
              name="sponser_id"
              autoComplete="sponer_id"
              defaultValue={token}
              autoFocus
              InputLabelProps={{
                style: { color: '#fff', fontSize: '12px' },
            }} 
            inputProps={{ style: { color: "#fff" } }} 
            />
            <Typography variant='body2' sx={{fontSize: '10px', marginTop: '2px', color: '#fff'}}>If you don't have the referral, please use <b>METAPAD</b></Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              InputLabelProps={{
                style: { color: '#fff', fontSize: '12px' },
            }} 
            inputProps={{ style: { color: "#fff" } }} 
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              InputLabelProps={{
                style: { color: '#fff', fontSize: '12px' },
            }} 
            InputProps={{
              style: { color: '#fff' },
              endAdornment: (
                  <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          edge="end"
                      >
                          {showPassword ? <Visibility style={{color: '#fff'}} /> : <VisibilityOff style={{color: '#fff'}}/>}
                      </IconButton>
                  </InputAdornment>
              ),
          }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="web3"
              label="Web3 Address  (BEP20 Binance Smart Chain)"
              name="web3_add"
              value={address}
              onChange={(event)=>{setAddress(event.target.value)}}
              autoComplete="web3_add"
              InputLabelProps={{
                style: { color: '#fff', fontSize: '12px' },
            }} 
            inputProps={{ style: { color: "#fff", fontSize: '12px' } }} 
            />
            <Typography variant='body2' sx={{fontSize: '10px', marginTop: '2px', color: '#fff'}}>Like Safepal or Meta Mask or Bitget address. </Typography>
            
            <Typography variant='body2' sx={{fontSize: '10px', marginTop: '5px', color: '#cc0000', animation: 'blink 1s infinite', // Apply the blink animation
              '@keyframes blink': { // Define the blink animation
                '0%': { color: '#cc0000' },
                '50%': { color: '#ffffff' }, // Change color to white or another color
                '100%': { color: '#cc0000' },
              }}}>* We didn't support Trust wallet</Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forget" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/login" variant="body2">
                  {"Have an account? Login"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      </div>
  );
}