import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import gradinant from '../assets/gradinat.webp'
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';

import bnbLogo from '../assets/bnb.png';
import metaLogo from '../assets/meta.png'
import WalletIcon from '@mui/icons-material/Wallet';
import Banner from '../assets/banner.png'

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Button } from '@mui/material';

import TextField from '@mui/material/TextField';
import Countdown from 'react-countdown';

import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {isMobile} from 'react-device-detect';

import PersonIcon from '@mui/icons-material/Person';
import Chip from '@mui/material/Chip';
import secureAxios from '../secureAxios';

import EmailIcon from '@mui/icons-material/Email';


import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { connectWallet, getBalance } from '../utils/transferTokensFunction';

import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import IBackdrop from '../components/backdrop.js';


const logos = {
    'bnb': bnbLogo,
    'metapad': metaLogo
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const urls = {
    0: 'https://x.com/@Metapad_cc',
    2: 'https://youtube.com/@METAPADofficial',
    1: 'https://t.me/Metapad_cc'
}

const User = () => {

    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [balance, setBalance] = React.useState(parseFloat(0));
    const [_socail, setSocial] = React.useState({0: false, 1: false, 2: false});
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(null);
    const [details, setDetail] = React.useState({
        profile: {
            name: 'Demo User',
            email: 'demo@demo.com'
        },
        wallet: [
            {   is_static: false,
                coin_name: 'BNB',
                value: parseFloat(0.000000000)
            },
            {   is_static: false,
                coin_name: 'METAPAD',
                value: 0
            }
        ]

    })
    const totalNumber = 5500000000

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClick = (socialMedia, id) => {
        setSelectedId(id);
        setOpenDialog(true);
      };
    
      const handleOpenWebsite = () => {
        console.log(selectedId)
        if (selectedId !== null) {
            console.log(urls[selectedId])
          let newWindow = window.open(urls[selectedId], '_blank', 'noopener,noreferrer');
          setOpenDialog(false);
        }
      };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const getSocialMediaName = (id) => {
        switch (id) {
          case 0:
            return 'Twitter';
          case 1:
            return 'Telegram';
          case 2:
            return 'YouTube';
          default:
            return '';
        }
      };

    const handleClickClaim = () => {
        handleClose();
        if (selectedId !== null) {
            //window.open(urls[id], '_blank', 'noopener,noreferrer');
            const username = prompt(`Enter your ${getSocialMediaName(selectedId)} username:`);
            if (username) {
            // Here you can handle what to do with the username, such as storing it or using it for further actions
            claim_social(selectedId);
            }
        }
      };

    const user_details = (data) => {
        setLoading(true);
        secureAxios.get(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/info`, data).then(response => {
    
          if (response.data){
            setLoading(false);
            setDetail(response?.data);
            setSocial(response?.data?.social)
            localStorage.setItem('address', response?.data?.profile?.address);
          }
        
    
        }).catch(error=>{
          setLoading(false)
          if (error.response.data?.non_field_errors){
            alert(error.response.data?.non_field_errors)
          }
          else{
            alert(error.response.data?.detail)
          }
        })
    }

    const claim_social = (id) => {
        setLoading(true);
        secureAxios.post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/claim_social`, {'social': id}).then(response => {
    
          if (response.data){
            setLoading(false);
            alert("Successfully completed")
            setSocial({..._socail, [id]: true})
          }
        
        }).catch(error=>{
          setLoading(false)
          if (error.response.data?.non_field_errors){
            alert(error.response.data?.non_field_errors)
          }
          else{
            alert(error.response.data?.detail)
          }
        })
    }

    React.useEffect(async ()=>{
        user_details();
        const { network, accounts } = await connectWallet();
        const balance = await getBalance(accounts);
        setBalance(balance)
    }, [])

    const formatNumber = (num) => {
        if (num >= 1e9) {
          return (num / 1e9).toFixed(1) + ' B';
        }
        if (num >= 1e6) {
          return (num / 1e6).toFixed(1) + ' M';
        }
        if (num >= 1e3) {
          return (num / 1e3).toFixed(1) + ' K';
        }
        return num.toString();
    };

    function stringToDate(dateString) {
        const [datePart, timePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hour, minute, second] = timePart.split(':').map(Number);
        
        // JavaScript months are zero-based, so we subtract 1 from the month
        return new Date(year, month - 1, day, hour, minute, second);
      }
  
      const CountdownTimer = ({date}) => {
  
        // Renderer function to format the countdown
        const renderer = ({ days, hours, minutes, seconds }) => {
          // Pad single digit numbers with leading zeros
          const pad = (num) => String(num).padStart(2, '0');
      
          return (
            <Typography variant="subtitle1" fontWeight={"bold"}  >
              {pad(days)}d : {pad(hours)}h : {pad(minutes)}m 
            </Typography>
          );
        };
        return (
            <Countdown
              date={date}
              renderer={renderer}
            />
          );
    };

    
    return (
        <>
         <IBackdrop isOpen={loading}/>
        <Card
            sx={{
                backgroundColor: `rgb(31, 31, 31)`,
                color: `primary.contrastText`,
                paddingBottom: 0
            }}
        >
            <Box>
                <CardContent sx={{paddingBottom: '16px !important'}}>
                    <Grid container spacing={2}>
                        <Grid item  xs={8} key={0}>
                            <Grid container spacing={1} justifyContent="left" alignItems="left">
                                    <Grid item sx={4}>
                                        
                                        <PersonIcon style={{width: '50px', height: '50px'}} />
                                        
                                    </Grid>
                                    <Grid item sx={8}>
                                        <Typography variant="subtitle1">Name : {details?.profile?.name}</Typography>
                                        <Typography variant="body2">Email : {details?.profile?.email}</Typography>
                                        <Typography variant="body2" style={{fontSize: '12px'}}>Username : {details?.profile?.username}</Typography>
                                        <Typography variant="body2" style={{fontSize: '12px'}}>Web3 Address : {details?.profile?.address}</Typography>
                                    </Grid>
                                    
                            </Grid>
                        </Grid>
                        <Grid item justifyContent={'right'}  textAlign={'right'} xs={4} key={1}>
                            <Link style={{color: '#fff'}} to={'/user_details'}><ChevronRightIcon style={{width: '30px', height: '30px', marginTop: '10px'}} /></Link>
                        </Grid>
                            
                    </Grid>                    
                </CardContent>
            </Box>
        </Card>


        <Card
            sx={{
                backgroundColor: `rgb(31, 31, 31)`,
                color: `primary.contrastText`,
                marginTop: '10px',
                paddingBottom: '16px'
            }}
        >
            <Box>
                <CardContent sx={{paddingBottom: '16px !important'}}>
                    
                    <Chip sx={{color: '#fff'}} label="Assets" />

                    <Grid container spacing={2}>

                        {details?.wallet.map((item, index) => {
                            return (
                                <Grid item xs={6}>
                                    <Grid container alignContent={'center'} justifyContent={'center'}>
                                        <Grid item>
                                            <Avatar sx={{ bgcolor: 'transparent' }}>
                                                <img src={logos[item?.coin_name.toLowerCase()]} width={30} height={30} />                                                
                                            </Avatar>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignContent={'center'} justifyContent={'center'}>
                                        <Grid item>
                                            <Typography variant='body2' fontSize={'10px'}>{item?.coin_name.toLowerCase() == 'bnb' ? balance : parseFloat(item?.value).toFixed(8)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignContent={'center'} justifyContent={'center'}>
                                        <Grid item>
                                            <Typography variant='body2' fontSize={'7px'}>{item?.coin_name}</Typography>
                                        </Grid>
                                    </Grid>
                                    
                                    
                                </Grid>
                            )
                        })}

                                             
                    </Grid>     

                    <Typography variant='body2' style={{textAlign: 'center', marginTop: '20px'}} fontSize={'14px'}>Withdrawal will be open after the completion of the airdrop</Typography>   

                            
                </CardContent>
            </Box>
        </Card>

        <div style={{margin: '0px auto', textAlign: 'center'}}>
      <Typography variant="body2" style={{marginTop: '20px', fontWeight: 'bold', marginBottom: '15px', color:'#fff'}} gutterBottom>
        Earn 50 kH/s power to subscribe or follow:
      </Typography>
        <Button
            variant="contained"
            color="primary"
            startIcon={<TwitterIcon />}
            onClick={() => handleClick('Twitter', 0)}
            disabled={_socail?.[0]}
            style={{ marginRight: '10px', marginBottom: '10px', color: '#fff' }}
        >
            Twitter
        </Button>
        <Button
            variant="contained"
            color="primary"
            disabled={_socail?.[1]}
            startIcon={<TelegramIcon />}
            onClick={() => handleClick('Telegram', 1)}
            style={{ marginRight: '10px', marginBottom: '10px', color: '#fff' }}
        >
            Telegram
        </Button>
        <Button
            variant="contained"
            color="primary"
            startIcon={<YouTubeIcon />}
            disabled={_socail?.[2]}
            onClick={() => handleClick('YouTube', 2)}
            style={{ marginBottom: '10px', color: '#fff' }}
        >
            YouTube
        </Button>
    </div>


        <Typography textAlign={'center'} style={{marginTop: '40px', color:'#fff'}} variant="body1" gutterBottom>
            <EmailIcon style={{ marginRight: '0.5em', verticalAlign: 'middle' }} />
            <span style={{ verticalAlign: 'middle' }}> Contact Us : support@metapad.cc</span>
        </Typography>   

        <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Choose an action</DialogTitle>
        <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
          <Button variant="contained" color="primary" onClick={handleOpenWebsite}>
            Open Website
          </Button>
          <Button style={{marginTop: '10px'}} variant="contained" color="primary" onClick={handleClickClaim}>
            Claim
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
        
    </>
    )
}

export default User