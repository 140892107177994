import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import gradinant from '../assets/gradinat.webp'
import Divider from '@mui/material/Divider';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';

import bnbLogo from '../assets/bnb.png';
import metaLogo from '../assets/meta.png'
import WalletIcon from '@mui/icons-material/Wallet';
import Banner from '../assets/banner.png'

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Button } from '@mui/material';

import TextField from '@mui/material/TextField';
import Countdown from 'react-countdown';

import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {isMobile} from 'react-device-detect';

import PersonIcon from '@mui/icons-material/Person';
import Chip from '@mui/material/Chip';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';

import { useHistory } from "react-router-dom";
import secureAxios from '../secureAxios';
import Web3 from 'web3';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import IBackdrop from '../components/backdrop.js';


const logos = {
    'bnb': bnbLogo,
    'metapad': metaLogo
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const UserDetails = () => {

    const [value, setValue] = React.useState(0);
    const [detail, setDetail] = React.useState(
      {'profile': {
      username: "METAPAD"
      }});
    const [loading, setLoading] = React.useState(false);
    let history = useHistory()
    const totalNumber = 5500000000;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const details = {
        teams: [
            {labelName: 'My Team Lavel 1', values: []},
            {labelName: 'My Team Lavel 2', values: []}
        ]

    }

    const user_details = (data) => {
      setLoading(true);
      secureAxios.post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/change_info`, data).then(response => {
  
        if (response.data){
          setLoading(false);
          alert("Successfully updated");
          history.push("/me")
        }
      
  
      }).catch(error=>{
        setLoading(false)
        if (error.response.data?.non_field_errors){
          alert(error.response.data?.non_field_errors)
        }
        else{
          alert(error.response.data?.detail)
        }
      })
  }


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const new_pass = data.get('new_pass');
        const cnf_pass = data.get('cnf_pass');
        const address = data.get('web3_add').trim();

        const web3 = new Web3();

    
        if (!web3.utils.isAddress(address)){
    
          alert("Invalid address")
          return false
    
        }
        


        if (address == "" ){
            alert("All fields are required");
            return
        }

        else if (new_pass != cnf_pass){
            alert("Password should be same");
            return
        }

        user_details({
            password: new_pass,
            web3: address
        })
    }

    
    return (
        <>
        <IBackdrop isOpen={loading}/>
        <Box sx={{ flexGrow: 1, backgroundColor: `rgb(31, 31, 31)` }}>
            <AppBar sx={{ flexGrow: 1, backgroundColor: `rgb(31, 31, 31)` }} position="static">
                <Toolbar>
                    <KeyboardArrowLeftIcon onClick={()=>history.push("/me")}/>
                    <Typography textAlign={'center'} variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        User Details
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ backgroundColor: `rgb(31, 31, 31)`, marginTop: '15px', padding: '20px' }}>
            
            <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="New Password"
                inputMode='password'
                name="new_pass"
                InputLabelProps={{
                    style: { color: '#fff', fontSize: '12px' },
                }} 
                inputProps={{ style: { color: "#fff" } }} 
            />
            <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Confirm Password"
                type='password'
                name="cnf_pass"
                InputLabelProps={{
                    style: { color: '#fff', fontSize: '12px' },
                }} 
                inputProps={{ style: { color: "#fff" } }} 
            />
            <TextField
                margin="normal"
                required
                fullWidth
                id="web3"
                label="Web3 Address  (BEP20 Binance Smart Chain)"
                name="web3_add"
                autoComplete="web3_add"
                defaultValue={localStorage.getItem('address')}
                InputLabelProps={{
                    style: { color: '#fff', fontSize: '12px' },
                }} 
                inputProps={{ style: { color: "#fff", fontSize: '12px' } }} 
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save
            </Button>

        </Box>
        
        </>
    )
}

export default UserDetails