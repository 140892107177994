import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import gradinant from '../assets/gradinat.webp'
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';

import bnbLogo from '../assets/bnb.png';
import metaLogo from '../assets/meta.png'
import WalletIcon from '@mui/icons-material/Wallet';
import Banner from '../assets/banner.png'
import Banner2 from '../assets/banner2.png'


import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Button } from '@mui/material';

import TextField from '@mui/material/TextField';
import Countdown from 'react-countdown';

import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {isMobile} from 'react-device-detect';
import secureAxios from '../secureAxios';

import moment from 'moment/moment';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import transferTokens, { connectWallet, transferUSDTTokens, getBalance } from '../utils/transferTokensFunction.js';
import IBackdrop from '../components/backdrop.js';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import {  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

import fav from '../assets/favicon-16x16.png';


const logos = {
    'bnb': bnbLogo,
    'metapad': metaLogo
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const Home = () => {

    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [buyCoinAmount, setbuyCoinAmount] = React.useState(0);
    const [balance, setBalance] = React.useState(parseFloat(0));
    const [open, setOpen] = React.useState(true)
    const [details, setDetail] = React.useState({
        claimCount: 1000000,
        claimValue: 0.0004,
        coinPrice: 0,
        transactDetails: [
            {
                title: 'My Hash',
                value: '2000000',
                showCountdown: false
            },
            {
                title: 'My Team',
                value: '4',
                showCountdown: false
            },
            {
                title: 'Airdrop Time',
                value: '2024-05-20 15:23:00',
                showCountdown: true
            }
        ],
        wallet: [
            {   is_static: false,
                coin_name: 'BNB',
                value: parseFloat(0.000000000)
            },
            {   is_static: false,
                coin_name: 'METAPAD',
                value: 0
            },
            {
                is_static: true
            }
        ],
        hashes: [
            {   amount: 10,
                hash_value: 100,
            },
            {   amount: 20,
                hash_value: 220,
            }, 
            {   amount: 40,
                hash_value: 480,
            }, 
            {   amount: 80,
                hash_value: 1000,
            }          
        ]

    });
    const totalNumber = 5500000000;

    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: '100% 100%',
        height: '150px',
        width: '100%'
      }
    const slideImages = [
        {
            url: Banner,
            caption: 'Slide 1'
        },
        {
            url: Banner2,
            caption: 'Slide 2'
        }
    ];

    const properties = {
        prevArrow: null,
        nextArrow: null
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const _connectWalletandProcess = async (data) => {
        setLoading(true);
        const { network, accounts } = await connectWallet();
        const txn = await transferTokens(accounts[0], parseFloat(details?.claimValue));
        if (txn){
            claim(data);
            return false
        }else{
            setLoading(false);
        }
        
    }


    const formatNumber = (num) => {
        if (num >= 1e9) {
          return (num / 1e9).toFixed(1) + ' B';
        }
        if (num >= 1e6) {
          return (num / 1e6).toFixed(1) + ' M';
        }
        if (num >= 1e3) {
          return (num / 1e3).toFixed(1) + ' K';
        }
        return num.toString();
    };

    function stringToDate(dateString) {
        const [datePart, timePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hour, minute, second] = timePart.split(':').map(Number);
        
        // JavaScript months are zero-based, so we subtract 1 from the month
        return new Date(year, month - 1, day, hour, minute, second);
      }
  
      const CountdownTimer = ({date}) => {
  
        // Renderer function to format the countdown
        const renderer = ({ days, hours, minutes, seconds }) => {
          // Pad single digit numbers with leading zeros
          const pad = (num) => String(num).padStart(2, '0');
      
          return (
            <Typography variant="subtitle1" fontWeight={"bold"}  >
              {pad(hours)}h : {pad(minutes)}m : {pad(seconds)}s 
            </Typography>
          );
        };
        return (
            <Countdown
              date={date}
              renderer={renderer}
            />
          );
    };

    const user_details = (data) => {
        setLoading(true);
        secureAxios.get(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/dashboard`, data).then(response => {
    
          if (response.data){
            setLoading(false);
            setDetail(response?.data)
          }
        
    
        }).catch(error=>{
          setLoading(false)
          if (error.response.data?.non_field_errors){
            alert(error.response.data?.non_field_errors)
          }
          else{
            alert(error.response.data?.detail)
          }
        })
      }

    const buy_hash = async (amount) => {
        setLoading(true);
        const data = {
            amount: amount
        }
        const { network, accounts } = await connectWallet();
        const txn = await transferUSDTTokens(accounts[0], parseFloat(amount));
        if (txn){
            
            secureAxios.post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/buy_hash`, data).then(response => {
        
            if (response.data){
                setLoading(false);
                alert("Successfully purchased");
                user_details()
            }
            
            }).catch(error=>{
                setLoading(false)
                if (error.response.data?.non_field_errors){
                    alert(error.response.data?.non_field_errors)
                }
                else{
                    alert(error.response.data?.detail)
                }
                })
            }
        else{
            setLoading(false);
        }

    }

    const handleChangeAmount = (event) => {
        setbuyCoinAmount(event.target.value);
    }


    const buy_coin = async () => {
        if (buyCoinAmount < 2){
            alert("Minimum 2 USDT")
            return false
        }
        setLoading(true);
       
        const { network, accounts } = await connectWallet();
        const txn = await transferUSDTTokens(accounts[0], parseFloat(buyCoinAmount));
        if (txn){

            const data = {
                amount: buyCoinAmount,
                hash: txn?.transactionHash
            }
            
            secureAxios.post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/buy_coin`, data).then(response => {
        
            if (response.data){
                setLoading(false);
                alert("Successfully purchased");
                user_details()
            }
            
            }).catch(error=>{
                setLoading(false)
                if (error.response.data?.non_field_errors){
                    alert(error.response.data?.non_field_errors)
                }
                else{
                    alert(error.response.data?.detail)
                }
                })
            }
        else{
            setLoading(false);
        }

    }

      const claim = (data) => {
        setLoading(true);
        secureAxios.post(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/claim`, {id: data}).then(response => {
    
          if (response.data){
            setLoading(false);
            user_details()
          }
        
    
        }).catch(error=>{
          setLoading(false)
          if (error.response.data?.non_field_errors){
            alert(error.response.data?.non_field_errors)
          }
          else{
            alert(error.response.data?.detail)
          }
        })
      }

    React.useEffect(async ()=>{
        user_details();
        const { network, accounts } = await connectWallet();
        const balance = await getBalance(accounts);
        setBalance(balance)
    }, [])

    
    return (
        <>
        <IBackdrop isOpen={loading}/>
        <Card
            sx={{
                backgroundColor: `primary.main`,
                color: `primary.contrastText`,
                paddingBottom: 0
            }}
        >
            <Box>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography fontSize={14} fontWeight={'bold'} variant="subtitle1">
                                Airdrop Coins
                            </Typography>
                            <Typography fontSize={18} fontWeight={'bold'} variant="subtitle1" color="primary.contrastText">
                                {totalNumber.toLocaleString()}
                            </Typography>
                        </Grid>
                        <Grid item style={{textAlign: 'center'}} xs={2}>
                            <Typography fontSize={14} fontWeight={'bold'} sx={{opacity: 0}} variant="subtitle1">/</Typography>
                            <Typography fontSize={18} fontWeight={'bold'} variant="subtitle1" color="primary.contrastText">
                                /
                            </Typography>
                        </Grid>
                        <Grid item textAlign={'right'} xs={4}>
                            <Typography fontSize={14} fontWeight={'bold'} variant="subtitle1">
                                Claims
                            </Typography>
                            <Typography fontSize={18} fontWeight={'bold'} variant="subtitle1" color="primary.contrastText">
                                {details?.claimCount.toLocaleString()}
                            </Typography>
                        </Grid>
                    </Grid>                    
                </CardContent>
            </Box>
        </Card>

        <Card
            sx={{
                backgroundColor: `rgb(31, 31, 31)`,
                color: `primary.contrastText`,
                marginTop: '15px',
                paddingBottom: 0
            }}
        >
            <Box>
                <CardContent>
                    <Grid container spacing={2}>
                        {details?.transactDetails.map((item, index)=>{
                            return (
                                
                                <Grid textAlign={'center'} key={index} item xs={!isMobile ? 4 : (item?.showCountdown ? 12 : 6)}>
                                    <Typography fontWeight={'bold'} variant="subtitle1">
                                        {item.title}
                                    </Typography>
                                    <Typography  fontWeight={'bold'} variant="body2" color="primary.contrastText">
                                        {!item?.showCountdown && formatNumber(item?.value)} {item.title == 'My Team' && <Link to={'/recurit'}><PersonAddAltIcon style={{fontSize: '13px', color: '#fff'}}/></Link>}
                                        {item?.showCountdown && <CountdownTimer date={stringToDate(item?.value)}/>}
                                    </Typography>
                                </Grid>
                            )
                        })}
                        <Grid item xs={12}>
                            <Divider style={{ background: 'rgba(0, 0, 0, 0.12)' }}/>
                        </Grid>

                        {details?.wallet.map((item, index) => {
                            return (
                                <Grid item  xs={!isMobile ? 4 : (item?.is_static ? 12 : 6)} key={index}>
                                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                                            <Grid item>
                                                
                                                {!item?.is_static && <Avatar sx={{ bgcolor: 'transparent' }}>
                                                    <img src={logos[item?.coin_name.toLowerCase()]} width={30} height={30} />                                                
                                                </Avatar>}
                                                
                                                {item?.is_static && <>
                                                    {isMobile && <Divider style={{ background: 'rgba(0, 0, 0, 0.12)', marginBottom: '15px' }}/>}
                                                    <Grid container alignItems="center" spacing={1}>
                                                        <Grid item>
                                                            <WalletIcon style={{ width: '40px', height: '30px' }} />
                                                        </Grid>
                                                        <Grid item>
                                                        <Link to={'/me'} style={{textDecoration: 'none'}}><Typography style={{color: '#fff'}} variant="caption">Click to wallet</Typography></Link>
                                                        </Grid>
                                                    </Grid>
                                                  </>
                                                } 
                                                
                                            </Grid>
                                            {!item?.is_static && <Grid item>
                                                <Typography variant="subtitle1">{item?.coin_name}</Typography>
                                                <Typography variant="body2">{item?.coin_name.toLowerCase() == 'bnb' ? balance : item.value}</Typography>
                                            </Grid>}
                                            
                                    </Grid>
                                </Grid>
                            )
                        })}
                          

                    </Grid> 
                                       
                </CardContent>


                
            </Box>
        </Card>

            <Box sx={{
                    backgroundColor: `rgb(31, 31, 31)`,
                    color: `primary.contrastText`,
                    marginTop: '15px',
                    paddingBottom: 0,
                }}>
                    <Slide {...properties}>
                        {slideImages.map((slideImage, index)=> (
                            <div key={index}>
                            <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                            </div>
                            </div>
                        ))} 
                    </Slide>
            </Box>

        
            <Box sx={{
                backgroundColor: `rgb(31, 31, 31)`,
                color: `primary.contrastText`,
                marginTop: '15px',
                paddingBottom: 0
            }}>               
                    
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab style={{color: '#fff'}} label="Airdrop" {...a11yProps(0)} />
                        <Tab style={{color: '#fff'}} label="Buy Hash Power" {...a11yProps(1)} />
                        <Tab style={{color: '#fff'}} label="Pre Sell" {...a11yProps(2)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                        {details?.claims?.filter((item) => !item?.is_claimed).length > 0 && <Typography variant='body2'>You have {details?.claims?.filter((item) => !item?.is_claimed).length} rounds airdrops unclaimed! Please claim it asap</Typography>}
                        <TableContainer component={Paper} style={{marginTop: '10px'}}>
                            <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{fontSize: '10px'}}>Hash / Date</StyledTableCell>
                                    <StyledTableCell style={{fontSize: '10px'}} align="center">Amount</StyledTableCell>
                                    {/* <StyledTableCell style={{fontSize: '10px'}} align="center">Claimed</StyledTableCell> */}
                                    {/* <StyledTableCell style={{fontSize: '10px'}} align="center">Remaing</StyledTableCell>
                                    <StyledTableCell style={{fontSize: '10px'}} align="center">Available</StyledTableCell> */}
                                    <StyledTableCell style={{fontSize: '10px'}} align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {disabled={item?.available_amount==0}} */}
                                {details?.claims?.map((item, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell style={{fontSize: '10px', color: '#fff'}}>
                                        #{10000 + item?.claim_id} / {moment(item?.date_created).format('MM-DD HH:mm')}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ fontSize: '10px', color: '#fff' }}>
                                        <Typography style={{display: 'flex', fontSize: '10px', alignItems: 'center', justifyContent: 'center'}} variant='body2'>
                                            <img src={fav} alt="icon" style={{ marginRight: '5px' }} />
                                            {parseFloat(item?.amount).toFixed(4)} Mint
                                        </Typography>
                                        
                                    </StyledTableCell>
                                    {/* <StyledTableCell style={{fontSize: '10px'}} align="center">{item?.claimed_amount}</StyledTableCell> */}
                                    {/* <StyledTableCell style={{fontSize: '10px'}} align="center">{item?.total_amount - item?.claimed_amount}</StyledTableCell>
                                    <StyledTableCell style={{fontSize: '10px'}} align="center">{item?.available_amount}</StyledTableCell> */}
                                    <StyledTableCell style={{fontSize: '10px', color: '#fff'}} align="center">
                                        <Button style={{fontSize: '10px', color: '#fff', borderColor: '#fff', cursor: 'pointer'}} className='animate-up-down' disabled={item?.is_claimed} variant='outlined'  onClick={() => {
                                                _connectWalletandProcess(item?.claim_id);
                                            
                                        }}>{!item?.is_claimed ? 'Claim' : 'Claimed'}</Button></StyledTableCell>
                                </StyledTableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </TableContainer>
                    
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>

                    <Grid container spacing={2}>
                        {details?.hashes.map((item, index) => {return (<>
                            <Grid item  xs={6}>
                                <Typography variant="body2">${item.amount} USDT = {item.hash_value} kH/s</Typography>
                            </Grid>
                            <Grid item  xs={6} textAlign={'right'}>
                                <Button onClick={()=>buy_hash(item.amount)} variant='outlined'>Buy Now</Button>
                            </Grid>
                            {details?.hashes.length -1 != index && <Grid item xs={12}>
                                <Divider style={{ background: 'rgba(0, 0, 0, 0.12)' }}/>
                            </Grid>}
                        </>)})}
                    </Grid>
                    
                </CustomTabPanel>

                <CustomTabPanel value={value} index={2}>
                    
                        <TextField 
                            id="standard-basic" 
                            fullWidth 
                            label="USDT Amount (Min 2 USDT)"
                            value={buyCoinAmount}
                            onChange={handleChangeAmount}
                            InputLabelProps={{
                                style: { color: '#fff', fontSize: '12px' },
                            }} 
                            inputProps={{ style: { color: "#fff" } }} 
                            variant="standard" />

                        <Typography variant='body2' sx={{fontSize: '12px', marginTop: '10px'}}>Coin Price {details?.coinPrice} USDT </Typography>
                        <Typography variant='body2' sx={{fontSize: '12px', marginTop: '10px'}}>You will receive {buyCoinAmount / details?.coinPrice} METAPAD Coin</Typography>
                        <Button 
                            variant='outlined'
                            onClick={buy_coin}
                            fullWidth
                            sx={{
                                marginTop: '20px'
                            }}
                        >
                            Buy Now
                        </Button>
                </CustomTabPanel>

                
                                                       
            </Box>
        
            <Dialog open={open} onClose={()=>{setOpen(false)}}>
                <DialogTitle>Airdrop Countdown</DialogTitle>
                <DialogContent>
                    <FlipClockCountdown labelStyle={{fontSize: '10px'}} digitBlockStyle={{ width: 25, height: 25, fontSize: 20 }} to={ new Date('2024-11-16T00:00:00Z').getTime()} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setOpen(false)}} color="primary">Close</Button>
                </DialogActions>
            </Dialog>

    </>
    )
}

export default Home