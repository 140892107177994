import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import gradinant from '../assets/gradinat.webp'
import Divider from '@mui/material/Divider';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';

import bnbLogo from '../assets/bnb.png';
import metaLogo from '../assets/meta.png'
import WalletIcon from '@mui/icons-material/Wallet';
import Banner from '../assets/banner.png'

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Button } from '@mui/material';

import TextField from '@mui/material/TextField';
import Countdown from 'react-countdown';

import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {isMobile} from 'react-device-detect';

import PersonIcon from '@mui/icons-material/Person';
import Chip from '@mui/material/Chip';

import { useHistory } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';


import secureAxios from '../secureAxios';
import IBackdrop from '../components/backdrop.js';


const logos = {
    'bnb': bnbLogo,
    'metapad': metaLogo
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  


const Team = () => {

    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [rows, setRows] = React.useState({1: [], 2: [], 3:[]})
    const totalNumber = 5500000000;
    let history = useHistory()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const details = {
        teams: [
            {labelName: 'My Team Lavel 1', values: []},
            {labelName: 'My Team Lavel 2', values: []},
            {labelName: 'My Team Lavel 3', values: []}
        ]

    }

    const formatNumber = (num) => {
        if (num >= 1e9) {
          return (num / 1e9).toFixed(1) + ' B';
        }
        if (num >= 1e6) {
          return (num / 1e6).toFixed(1) + ' M';
        }
        if (num >= 1e3) {
          return (num / 1e3).toFixed(1) + ' K';
        }
        return num.toString();
    };

    function stringToDate(dateString) {
        const [datePart, timePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hour, minute, second] = timePart.split(':').map(Number);
        
        // JavaScript months are zero-based, so we subtract 1 from the month
        return new Date(year, month - 1, day, hour, minute, second);
      }
  
      const CountdownTimer = ({date}) => {
  
        // Renderer function to format the countdown
        const renderer = ({ days, hours, minutes, seconds }) => {
          // Pad single digit numbers with leading zeros
          const pad = (num) => String(num).padStart(2, '0');
      
          return (
            <Typography variant="subtitle1" fontWeight={"bold"}  >
              {pad(days)}d : {pad(hours)}h : {pad(minutes)}m 
            </Typography>
          );
        };
        return (
            <Countdown
              date={date}
              renderer={renderer}
            />
          );
    };

    const user_details = () => {
      setLoading(true);
      secureAxios.get(`${process.env.REACT_APP_SERVICE_PYTHON_API_URL}/user/team`).then(response => {
  
        if (response.data){
          setLoading(false);
          setRows(response.data);
        }
      
  
      }).catch(error=>{
        setLoading(false)
        if (error.response.data?.non_field_errors){
          alert(error.response.data?.non_field_errors)
        }
        else{
          alert(error.response.data?.detail)
        }
      })
  }

  React.useEffect(()=>{
    user_details()
  }, [])

    
    return (
        <>
        <IBackdrop isOpen={loading}/>
        <Box sx={{
                backgroundColor: `rgb(31, 31, 31)`,
                color: `primary.contrastText`,
                marginTop: '15px',
                paddingBottom: 0
            }}>               
                    
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Tabs sx={{marginTop: '-6px'}} variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} aria-label="basic tabs example">
                                {details.teams?.map((item, index) => {return (
                                    <Tab style={{color: '#fff'}} label={item?.labelName} {...a11yProps(index)} />
                                )})}
                            </Tabs>
                        </Grid>
                        <Grid item xs={4} justifyContent={'right'} alignItems={'right'}>
                            <div style={{textAlign: 'right', paddingRight: '10px'}}>
                               <Button onClick={()=>history.push("/recurit")} variant='outlined'>Recurit</Button>
                            </div>
                        </Grid>

                        <Grid item xs={12}>

                          <CustomTabPanel value={value} index={0}>
                            <TableContainer component={Paper}>
                              <Table aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell fontSize={10}>ID/Registration time</StyledTableCell>
                                    <StyledTableCell align="right">Hash</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows?.[1].length == 0 && <StyledTableRow>
                                    <StyledTableCell align='center'>No Records are here</StyledTableCell>
                                  </StyledTableRow>}
                                  {rows?.[1].map((row, index) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell component="th" scope="row">
                                        {row.id}
                                        <span style={{fontSize: '10px', display: 'block'}}>({row?.address})</span>
                                      </StyledTableCell>
                                      <StyledTableCell align="right">{row.hash}</StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CustomTabPanel>


                          <CustomTabPanel value={value} index={1}>
                            <TableContainer component={Paper}>
                              <Table aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>ID/Registration time</StyledTableCell>
                                    <StyledTableCell align="right">Hash</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                {rows?.[2].length == 0 && <StyledTableRow>
                                    <StyledTableCell align='center'>No Records are here</StyledTableCell>
                                  </StyledTableRow>}
                                  {rows?.[2].map((row, index) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell component="th" scope="row">
                                        {row.id}
                                        <span style={{fontSize: '10px', display: 'block'}}>({row?.address})</span>
                                      </StyledTableCell>
                                      <StyledTableCell align="right">{row.hash}</StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CustomTabPanel>


                          <CustomTabPanel value={value} index={2}>
                            <TableContainer component={Paper}>
                              <Table aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>ID/Registration time</StyledTableCell>
                                    <StyledTableCell align="right">Hash</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows?.[3].length == 0 && <StyledTableRow>
                                    <StyledTableCell align='center'>No Records are here</StyledTableCell>
                                  </StyledTableRow>}
                                  {rows?.[3].map((row, index) => (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell component="th" scope="row">
                                        {row.id}
                                        <span style={{fontSize: '10px', display: 'block'}}>({row?.address})</span>
                                      </StyledTableCell>
                                      <StyledTableCell align="right">{row.hash}</StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CustomTabPanel>

                          

                        </Grid>

                    </Grid>
                    
                </Box>

        </Box>

        
    </>
    )
}

export default Team